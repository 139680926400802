import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="portfolios--links--create"
export default class extends Controller {
  static targets = ["loader", "form", "formField"];

  static values = {
    accessToken: String,
    modalId: String
  }

  connect() {
    this.loaderTarget.remove();
    this.openFastlink();
  }

  cancel() {
    this.closeFastlink();
    this.closeModal();
  }

  closeFastlink() {
    window.fastlink.close();
  }

  closeModal() {
    this.modal().hide();
  }

  config() {
    return {
      fastLinkURL: 'https://finapp.fingoalchannel.yodlee.com/authenticate/fuchsfinancial-production/fastlink?channelAppName=fingoalchannel',
      accessToken: `Bearer ${this.accessTokenValue}`,
      params: {
        configName: 'aggregation'
      },
      onSuccess: this.onSuccess.bind(this),
      onError: (data) => {
        console.log("onError", data);
      },
      onClose: (data) => {
        console.log("onClose", data);
        this.closeModal();
      },
      onEvent: (data) => {
        console.log("onEvent", data);
      }
    }
  }

  modal() {
    return Modal.getOrCreateInstance(`#${this.modalIdValue}`);
  }

  openFastlink() {
    window.fastlink.open(
      this.config(),
      "portfolios_links_create_embed"
    );
  }

  onSuccess(data) {
    /*
      {
        "providerId": 19335,
        "providerName": "Dag OAuth",
        "requestId": "5jJMeWG2RWmxLtNlOScqYynBmBY=",
        "status": "SUCCESS",
        "additionalStatus": "ACCT_SUMMARY_RECEIVED",
        "providerAccountId": 11343698,
        "fnToCall": "accountStatus"
      }
    */
    console.log("onSuccess", data, this.element, this.formFieldTarget);
    this.formFieldTarget.value = JSON.stringify(data);
    this.formTarget.requestSubmit();
  }

  restart() {
    this.closeFastlink();
    this.openFastlink();
  }
}
